.amcham-box {
  margin: 0 auto;
  opacity: 0.9;
  
  font-family: 'foco_lightregular';
  background-color: white;

  &.large {
    max-width: 850px;
  }

  &.small {
    max-width: 450px;
  }

  &.myaccount {
    max-width: 100%;
  }

  .amcham-box-header {
    padding: 5px 10px;
    border-bottom: 1px solid #e3e3e3;
  }

  .amcham-box-body {
    
    .event-detail {
      padding: 20px;
  
      background-color: #34495e;
      a {
        color: #fff;
        font-size: 14px;

        text-decoration: none;
        cursor: pointer;
        
        // Resize in screens smaller than lg.
        //@media (max-width: 992px) {
          //  margin-left: 15px;
        //}

        i {
            //padding-right: 2.5px;

            // Resize in screens wider than lg.
            //@media (min-width: 992px) {
              //  font-size: 18px;
            //}
        }

        &:hover {
            opacity: 0.5;
            transition: opacity 0.2s linear;
        }
    }

      .event-item {
        color: #ecf0f1;
  
        h1 {
          font-size: 16px;
          font-family: 'focoregular';
        }
    
        p {
          font-size: 14px;
        }
    
        i {
          margin-right: 5px;
        }
      }
    }
  
    .user-actions {
      padding: 20px;
      background-color: white;
    }
  }
  
}
/* amcham - dashboard */

.amcham-dashboard {
  margin: 0 auto;
  opacity: 0.9;
  
  font-family: 'foco_lightregular';
  background-color: white;

  &.large {
    max-width: 100%;
  }

  &.small {
    max-width: 450px;
  }

  .amcham-dashboard-header {
    padding: 5px 10px;
    border-bottom: 1px solid #e3e3e3;
  }

  .amcham-dashboard-body {
    
    .dashboard-menu {
      padding: 20px;
  
      background-color: #34495e;
      
      .dashboard-menu-item{
        color: #ecf0f1;
  
        h1 {
          font-size: 16px;
          font-family: 'focoregular';
        }
    
        p {
          font-size: 14px;
        }
    
        i {
          margin-right: 5px;
        }
      }
    }
  
    .dashboard-content {
      padding: 5px;
      background-color: white;
      .row {
        margin-right: 0;
        margin-left: 0;
      }
    }

    .card {
        .item {
          border-bottom:1px solid rgba(0,0,0,.125);
        }
    }
  }
  
}